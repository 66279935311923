<template>
  <div class="row">
    <div class="col-12 col-lg-6 c g">
      <div class="card">
        <div class="card-header">
          <h4>اعادة رفع تقارير اجهزة البصمة</h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label for="">اختر التاريخ</label>
            <input
              type="date"
              class="form-control"
              v-model="date"
              name=""
              id=""
              aria-describedby="helpId"
              placeholder=""
            />
          </div>
          <h5>اختر الاجهزة:</h5>
          <div class="row g">
            <div
              class="col-12 col-lg-6 g"
              v-for="device in devices"
              :key="device._id"
            >
              <div class="form-check">
                <label class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    v-model="selected_devices"
                    :value="device.serial_number"
                  />
                  <h4>
                    {{ device.title }}
                    <br />
                    <small>
                      {{ device.serial_number }}
                    </small>
                  </h4>
                </label>
              </div>
            </div>
          </div>
          <div class="col-12 text-center">
            <button
              :class="['btn btn-primary', loading ? 'disabled' : '']"
              @click="preview()"
            >
              استعلام عن التقارير
            </button>
            <div class="text-center g" v-if="loading">جاري التحميل...</div>
          </div>
        </div>
        <div class="card-footer" v-if="view">
          <div class="alert alert-danger text-center g" v-if="logs.length == 0">
            لا يوجد تقارير محفوظة سابقاً لهذا التاريخ لهذه الاجهزة.
          </div>
          <div v-if="logs.length">
            <div class="col-12 text-center g">
              <button
                :class="['btn btn-relief-success', loading ? 'disabled' : '']"
                @click="reupload()"
              >
                اعادة رفع هذه التقارير
              </button>
              <div class="text-center g" v-if="loading">جاري التحميل...</div>
            </div>
            <div class="col-12 table-responsive">
              <table class="table table-hover">
                <thead>
                  <th>الجهاز</th>
                  <th>رقم الهوية</th>
                  <th>التوقيت</th>
                </thead>
                <tbody>
                  <tr v-for="log in logs" :key="log._id">
                    <td>
                      {{ log.serial_number }}
                    </td>
                    <td>
                      {{ log.id }}
                    </td>
                    <td>
                      {{ log.time }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
const axios = require("axios");
export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
  data() {
    return {
      date: new Date().toISOString().toString().split("T")[0],
      devices: [],
      selected_devices: [],
      logs: [],
      view: false,
      loading: false,
    };
  },
  created() {
    var g = this;
    axios
      .post(localStorage.getItem("api0") + "/devices/index", {
        jwt: localStorage.getItem("jwt"),
      })
      .then(function (r) {
        g.devices = r.data;
      });
  },
  methods: {
    preview() {
      var g = this;
      this.loading = true;
      axios
        .post(localStorage.getItem("api0") + "/devices/logs", {
          jwt: localStorage.getItem("jwt"),
          devices: this.selected_devices,
          date: this.date,
        })
        .then(function (r) {
          g.logs = r.data;
          g.view = true;
          g.loading = false;
        });
    },
    reupload() {
      var g = this;
      this.loading = true;
      axios
        .post(localStorage.getItem("api0") + "/devices/reupload-logs", {
          jwt: localStorage.getItem("jwt"),
          devices: this.selected_devices,
          date: this.date,
        })
        .then(function (r) {
          g.logs = r.data;
          g.view = true;
          g.loading = false;
          alert("تم اعادة الرفع بنجاح. \n جاري الآن معالجة التقارير..");
        });
    },
  },
};
</script>

<style>
</style>
